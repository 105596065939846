<template>
    <div class="container_holder">
        <h1>Radio Body</h1>
    </div>
</template>

<script>
    export default {
        name: "RadioBody",
    }
</script>

<style scoped>
    
</style>

