<template>
    <div class="authentication">
        <a href="#">
            <img src="./assets/apple.svg" style="width: 18px;height:18px;">
        </a>
        <a href="#">
            <img src="./assets/facebook.svg" style="width: 18px;height:18px;">
        </a>
        <a href="#">
            <img src="./assets/google.svg" style="width: 18px;height:18px;">
        </a>
    </div>
</template>

<script>
export default {
    name: "SocialLogin"
}
</script>